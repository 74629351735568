import { flagsConfig, RewardSystemPromotions } from '@tn/shared';

export default defineNuxtRouteMiddleware((to, from) => {
  const { $getVariant } = useNuxtApp();
  const variant = $getVariant(flagsConfig.REWARD_SYSTEM_PROMOTIONS);
  const isPromotionsDisabled =
    variant?.value === RewardSystemPromotions.WebInbox;

  if (isPromotionsDisabled) {
    throw createError({ statusCode: 404, message: 'Promotions not found' });
  }
});
